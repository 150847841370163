// Generated by @nuxtjs/svg-sprite
export const sprites = {
  "icons": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/icons.svg").then(r => r.default || r),
  "account": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/account.svg").then(r => r.default || r),
  "art": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/art.svg").then(r => r.default || r),
  "contact": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/contact.svg").then(r => r.default || r),
  "graph": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/graph.svg").then(r => r.default || r),
  "home": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/home.svg").then(r => r.default || r),
  "payment": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/payment.svg").then(r => r.default || r),
  "support": () => import("/builds/robustastudio/midea/miraco-website/assets/sprite/gen/support.svg").then(r => r.default || r)
}
export const spriteClass = "";

export const spriteClassPrefix = "";

export const defaultSprite = "icons";
